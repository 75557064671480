<div><slot /></div>

<style lang="scss">
  @import '../../scss/variables.scss';

  div {
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
</style>
